import React, {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {jsPDF} from 'jspdf';
import {ReactComponent as Logo} from "../images/logo.svg";
import {ReactComponent as Banner} from "../images/banner.svg";
import Table from 'react-bootstrap/Table';
import html2canvas from "html2canvas";

const date = new Date();
const today = date.toLocaleDateString('en-GB', {
  month: 'numeric',
  day: 'numeric',
  year: 'numeric',
});
const InvoiceModal = ({
  isOpen,
  setIsOpen,
  invoiceInfo,
  items,
  onAddNextInvoice,
}) => {
  function closeModal() {
    setIsOpen(false);
  }

  const addNextInvoiceHandler = () => {
    setIsOpen(false);
    onAddNextInvoice();
  };

  const createPDF = async () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#print"));
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("Cotizacion.pdf");
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="rounded-md bg-white p-6 shadow-sm sm:space-y-8 opacity-100" style={{width: "1000px", marginTop: "-850px", marginLeft: "500px", fontSize:"18px"}}>
              <div id="print">
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: '-120px'}}>
                  <Banner style={{width:"100%"}} />
                </div>
                <div className="row justify-content-center">
                  <span className="font-bold">Fecha: </span>
                  <span>{today}</span>
                </div>
                <div className="row justify-content-center">
                  <span className="font-bold">Número de cotización:</span>
                  <span>{invoiceInfo.invoiceNumber}</span>
                  <div className="row justify-content-center">
                    <span className="font-bold">Vendedor:</span>
                    <span>{invoiceInfo.cashierName}</span>
                  </div>
                  <div className="row justify-content-center">
                    <span className="font-bold">Cliente:</span>
                    <span>{invoiceInfo.customerName}</span>
                  </div>
                </div>
                <br />  <br />
                <div className="row justify-content-center" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <Table striped bordered style={{background: "#F6C784", justifyContent: "center", textAlign:"center", border:"1px solid black"}} className="text-left" width="80%">
                    <thead style={{background: "#C76020", verticalAlign:"center"}}>
                      <tr className="border-y border-black/10 text-sm md:text-base" style={{border:"1px solid black"}}>
                        <th style={{border:"1px solid black"}}>DESCRIPCIÓN</th>
                        <th className="text-center" style={{border:"1px solid black"}}>CANTIDAD</th>
                        <th className="text-right" style={{border:"1px solid black"}}>PRECIO</th>
                        <th className="text-right" style={{border:"1px solid black"}}>SUBTOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item) => (
                        <tr key={item.id}>
                          <td className="w-full" style={{border:"1px solid black"}}>{item.name}</td>
                          <td className="min-w-[50px] text-center" style={{border:"1px solid black"}}>
                            {item.qty}
                          </td>
                          <td className="min-w-[80px] text-right" style={{border:"1px solid black"}}>
                            ${Number(item.price).toFixed(2)}
                          </td>
                          <td className="min-w-[90px] text-right" style={{border:"1px solid black"}}>
                            ${Number(item.price * item.qty).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <br />  <br />
                <div className="row justify-content-center">
                  <span className="font-bold">Subtotal:</span>
                  <span>${invoiceInfo.subtotal.toFixed(2)}</span>
                </div>
                <div className="row justify-content-center">
                  <span className="font-bold">Descuento:</span>
                  <span>${invoiceInfo.discountRate.toFixed(2)}</span>
                </div>
                <div className="row justify-content-center">
                  <span className="font-bold">IVA:</span>
                  <span>${invoiceInfo.taxRate.toFixed(2)}</span>
                </div>
                <div className="row justify-content-center">
                  <span className="font-bold">Total:</span>
                  <span className="font-bold">
                    $
                    {invoiceInfo.total % 1 === 0
                      ? invoiceInfo.total
                      : invoiceInfo.total.toFixed(2)}
                  </span>
                </div>
                <br />
                {/* <Banner style={{height: "10%", width:"100%"}} /> */}
              </div>
              <div className="row" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div className="col" style={{width: "30%"}}>
                  <button
                    className="flex w-full items-center justify-center space-x-1 rounded-md border border-blue-500 py-2 text-sm text-blue-500 shadow-sm hover:bg-yellow-500 hover:text-white"
                    onClick={createPDF}
                    style={{borderColor: "#C76020"}}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="#C76020"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                        fill="#C76020"
                      />
                    </svg>
                    <span style={{color: "#C76020"}}>Descargar</span>
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InvoiceModal;
