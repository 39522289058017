import InvoiceForm from './components/InvoiceForm';
import { ReactComponent as Logo } from "./images/logo.svg";

function App() {
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="mx-auto max-w-7xl">
        <Logo style={{marginTop: "100px"}}/>
        <InvoiceForm />
      </div>
    </div>
  );
}

export default App;
